<template>
  <v-container :fluid="$vuetify.breakpoint.mobile">
    <div class="section">
      <h2>Concours de créateur</h2>
    </div>
    <v-row>
      <v-col cols="12">
        <v-img
            alt="Logo Sablier Ludinam"
            class="ma-auto"
            contain
            src="../../assets/image/sablier.png"
            max-width="200px"
        />
      </v-col>
      <v-col cols="12" v-html="text"/>
      <v-col cols="12">
        <v-card target="_blank" :href="url" class="ma-auto text-center" max-width="200px">
          <v-card-title class="card-title">Télécharger le pdf</v-card-title>
          <v-img src="../../assets/image/pdf.png" height="200px"></v-img>
          <v-btn icon>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GlobalParamsService from "../../services/global_params.service";
export default {
  name: "Createur",
  data: () => ({
    url:null,
    text:null,
  }),
  created() {
    GlobalParamsService.getConcoursCreateur().then(response => {
      this.url = response['data'].value.url;
      this.text = response['data'].value.text;
    });
  },
};
</script>

<style lang="scss" scoped>
</style>
